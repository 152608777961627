import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemBody,
} from './components/Accordion';

const FAQ = () => (
  <section className="layout layout--middle faq">
    <div className="faq__container">
      <h2 className="faq__header">Pytania i odpowiedzi</h2>
      <Accordion>
        <AccordionItem>
          <AccordionItemHeading>
            Jak wstąpić do Razem?
          </AccordionItemHeading>
          <AccordionItemBody>
            <p>
              Do Razem może wstąpić każda dorosła osoba.
              Należy wypełnić formularz zgłoszenia na stronie.
              Następnie, lokalna osoba koordynująca skontaktuje się z Tobą w celu umówienia
              pierwszego spotkania (również on-line).
              Proponujemy zwykle na nim wypełnienie deklaracji wstąpienia do Razem.
              Podpisanie deklaracji jest momentem, od którego zaczyna się liczyć 90 dni okresu
              wstępnego.
            </p>
            <p className="text-center">
              Zgłoszenie przez stronę www<br />
              ↓<br />
              Wymiana e-maili z osobą koordynującą regionu<br />
              ↓<br />
              Udział w spotkaniu dla nowych lub indywidualne spotkanie z
              osobą koordynującą<br />
              ↓<br />
              Podpisanie deklaracji członkowskiej i złożenie jej do Zarządu Okręgu = rozpoczęcie
              okresu wstępnego<br />
              ↓<br />
              90 dni okresu wstępnego<br />
              ↓<br />
              Decyzja o wstąpieniu/przyjęciu do Razem
            </p>
          </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            Na czym polega okres wstępny w Razem?
          </AccordionItemHeading>
          <AccordionItemBody>
            <p>
              To okres 90 dni po złożeniu deklaracji wstąpienia do Razem.
              Możesz w tym czasie zapoznać się z naszym programem, brać udział w działaniach i
              spotkaniach.
              Po upływie trzech miesięcy od złożenia deklaracji, Zarząd Okręgu podejmie decyzję o
              pełnym członkostwie w Partii.
            </p>
          </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            Co to znaczy być Członkinią/Członkiem Razem?
          </AccordionItemHeading>
          <AccordionItemBody>
            <p>
              Członkinią/Członkiem Razem może zostać każda dorosła osoba posiadająca polskie
              obywatelstwo.
              Wstępując do Partii zobowiązujesz się do przestrzegania Statutu i programu Razem.
            </p>
            <p>Osoby członkowskie mają prawo do:</p>
            <ol>
              <li>wybierania władz Razem i bycia wybieranymi do tych władz;</li>
              <li>składania wniosków, inicjatyw politycznych i organizacyjnych;</li>
              <li>udziału we wszystkich działaniach Razem;</li>
              <li>do kandydowania w wyborach powszechnych z list Razem.</li>
            </ol>
            <p>Mają jednocześnie obowiązek:</p>
            <ol>
              <li>przestrzegać postanowień Statutu i uchwał władz Razem;</li>
              <li>postępować zgodnie z celami i programem partii;</li>
              <li>brać udział w realizacji programu oraz popularyzować działalność Razem;</li>
              <li>
                uczestniczyć w kampanii wyborczej do parlamentu i samorządów oraz innych organów;
              </li>
              <li>regularnie opłacać składki członkowskie.</li>
            </ol>
          </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            Jak wspomóc partię finansowo?
          </AccordionItemHeading>
          <AccordionItemBody>
            <p>Aby wspomóc Razem finansowo, wystarczy przelać pieniądze na konto partii.</p>
            <p>
              Lewica Razem<br />
              ul. Nowy Świat 27, 00-029 Warszawa<br />
              Numer konta: 41 1240 1037 1111 0010 6408 8296<br />
              Tytuł: „Darowizna na partię”
            </p>
            <p>
              Uwaga: polskie prawo zezwala partiom przyjmować darowizny pochodzące wyłącznie z kont
              krajowych oraz od osób fizycznych.
            </p>
            <p>
              <a href="https://wesprzyj.partiarazem.pl">
                https://wesprzyj.partiarazem.pl
              </a>
            </p>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </div>
  </section>
);

export default FAQ;

import React, { useReducer, useCallback, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import LabelWithInput from './components/LabelWithInput';
import LabelWithDatalistInput from './components/LabelWithDatalistInput';
import { ALL_COUNTRIES } from '../constants';

const defaultForm = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '+48',
  country: 'Polska',
  city: '',
  zipCode: '',
  policy: false,
};

const reducer = (state, action) => {
  const [name, value] = action;

  if (Object.prototype.hasOwnProperty.call(defaultForm, name)) {
    if (name === 'country' && value !== defaultForm.country) {
      return {
        ...state,
        form: {
          ...state.form,
          zipCode: defaultForm.zipCode,
          [name]: value,
        },
      };
    }

    return {
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    };
  }

  return {
    ...state,
    [name]: value,
  };
};

function handle400s(message) {
  let out = 'Wystąpił błąd';
  if (message) {
    out += `: ${message}`;
  }
  // eslint-disable-next-line no-alert
  alert(out);
}

function handle500s() {
  // eslint-disable-next-line no-alert
  alert('Błąd serwera! Jeśli będzie się powtarzał prosimy o kontakt');
}

function unexpectedError() {
  // eslint-disable-next-line no-alert
  alert('Nieoczekiwany błąd! Jeśli będzie się powtarzał prosimy o kontakt z nami');
}

const ApplyFormPage = ({ recaptchaSiteKey }) => {
  const [state, dispatch] = useReducer(reducer, { form: defaultForm });
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);

  const { form } = state;

  function handleSuccess() {
    setSuccess(true);
  }

  const recaptchaRef = React.useRef();

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    if (sending) {
      return;
    }

    setSending(true);

    recaptchaRef.current.executeAsync().then((token) => {
      const req = fetch(`/api/join?recaptchaResponse=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });
      // const req = Promise.resolve({ ok: false, status: 400, json: () => Promise.resolve({ }) });
      // const req = Promise.resolve({ ok: false, status: 400, json: () => Promise.resolve({ message: 'coś poszło nie tak' }) });
      // const req = Promise.resolve({ ok: false, status: 500 });
      // const req = Promise.resolve({ ok: true });
      return req;
    }).then((r) => {
      if (r.ok) {
        handleSuccess();
      } else if (r.status < 500) {
        r.json().then((data) => handle400s(data.message)).catch(handle400s);
      } else {
        handle500s();
      }
    }).catch(() => unexpectedError)
      .finally(() => {
        setSending(false);
        recaptchaRef.current.reset();
      });
  }, [form, sending]);

  const recaptchaElement = (
    <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey={recaptchaSiteKey}
    />
  );

  const leadElement = (
    <section className="lead lead--razem lead--padded">
      <div className="lead__content">
        <h1>Dołącz do Razem</h1>
        <p>
          Ważne są dla Ciebie wolność, równość i siostrzeństwo?<br/>
          Chcesz Polski świeckiej, która nie zostawia nikogo w potrzebie?
        </p>
        <p>
          Dołącz do nas!
          Działaj na rzecz lepszego jutra!
        </p>
      </div>
    </section>
  );

  const successElement = (
    <section className="layout layout--middle layout--pull-up">
      <div className="paper paper--center paper--success">
        <h1>Dziękujemy za wypełnienie zgłoszenia</h1>
        <p>
          W ciągu 7 dni zgłosi się do Ciebie osoba koordynująca z regionu.
        </p>
      </div>
    </section>
  );

  const formElement = (
    <section className="layout layout--middle layout--pull-up">
      <div className="paper">
        <form action="#" onSubmit={handleSubmit} className="form">
          <LabelWithInput
            text="Imię"
            value={form.firstName}
            name="firstName"
            onChange={dispatch}
          />
          <LabelWithInput
            text="Nazwisko"
            value={form.lastName}
            name="lastName"
            onChange={dispatch}
          />
          <LabelWithInput
            text="Email"
            value={form.email}
            name="email"
            type="email"
            onChange={dispatch}
          />
          <LabelWithDatalistInput
            text="Państwo"
            value={form.country}
            values={ALL_COUNTRIES}
            name="country"
            onChange={dispatch}
          />
          <LabelWithInput
            text="Telefon"
            value={form.phone}
            name="phone"
            type="tel"
            // Albo polski 9 cyfr, albo bez walidacji długości.
            pattern="\+(48[0-9]{9}|[0-35-9][0-9]+|4[0-79][0-9]+)"
            onChange={dispatch}
          />
          <LabelWithInput
            text="Miejscowość"
            value={form.city}
            name="city"
            onChange={dispatch}
          />
          <LabelWithInput
            text="Kod pocztowy"
            value={form.country === defaultForm.country ? form.zipCode : 'tylko dla Polski'}
            name="zipCode"
            pattern="[0-9]{2}-[0-9]{3}"
            onChange={dispatch}
            disabled={form.country !== defaultForm.country}
          />
          <div className="privacy-policy">
            Przed wypełnieniem zgłoszenia zapoznaj się z naszą <a
              href="https://partiarazem.pl/polityka-prywatnosci"
              target="_blank"
              rel="noopener noreferrer"
            >
              polityką prywatności
            </a>.
          </div>
          <LabelWithInput
            text="Wyrażam zgodę na przetwarzanie danych w celu obsługi zgłoszenia i nawiązania kontaktu z partią Razem. Wyrażam zgodę na przetwarzanie adresu email w celu otrzymywania wiadomości od partii Razem, informacji o jej działaniach oraz kampaniach. Zgoda może być wycofana w każdej chwili."
            checked={!!form.policy}
            type="checkbox"
            name="policy"
            onChange={dispatch}
          />
          <button
            type="submit"
            className="button"
            style={{
              gridArea: 'submit',
            }}
          >
            {sending ? 'Wysyłanie' : 'Dołącz'}
          </button>
          <div className="recaptcha">
            <p>
              Korzystamy z reCAPTCHA od Google: <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer noopener"
              >polityka prywatności</a> i <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noreferrer noopener"
              >warunki korzystania</a>.
            </p>
            {recaptchaElement}
          </div>
        </form>
      </div>
    </section>
  );

  return (
    <>
      {leadElement}
      {success ? successElement : formElement}
    </>
  );
};

export default ApplyFormPage;

import React from 'react';
import { render } from 'react-dom';
import ApplyFormPage from './pages/ApplyFormPage';
import FAQ from './pages/FAQ';

function doRender(recaptchaSiteKey) {
  const root = document.getElementById('react-root');

  render(
    <>
      <ApplyFormPage recaptchaSiteKey={recaptchaSiteKey} />
      <FAQ />
    </>,
    root,
  );
}

const init = () => {
  fetch('/api/config')
    .then((res) => res.json())
    .then((config) => doRender(config.recaptchaSiteKey))
    .catch(() => doRender('6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'));
};

init();

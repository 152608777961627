import React from 'react';

export const Accordion = ({ children }) => (
  <div className="accordion">
    {children}
  </div>
);

export const AccordionItem = ({ children }) => (
  <details className="accordion__item">
    {children}
  </details>
);

export const AccordionItemHeading = ({ children }) => (
  <summary>
    {children}
  </summary>
);

export const AccordionItemBody = ({ children }) => (
  <div className="accordion__details__body">
    {children}
  </div>
);

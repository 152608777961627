import React from 'react';
import classNames from 'html-classnames';
import { defaultSanitizer, numberSanitizer, useHandleInputOnChange } from '../hooks';

const LabelWithInput = ({
  text,
  name,
  id = name,
  value,
  type = 'text',
  pattern,
  optional = false,
  onChange,
  ...rest
}) => {
  const sanitizer = type === 'number' ? numberSanitizer : defaultSanitizer;
  return (
    <label
      className={classNames('form__label', {
        '--with-input': true,
        [`--${name}`]: true,
        [`--${type}`]: true,
      })}
      style={{
        gridArea: name,
      }}
      htmlFor={id}
    >
      <span>{text}{optional ? ' (opcjonalnie)' : ''}</span>
      <input
        onChange={useHandleInputOnChange(onChange, sanitizer)}
        type={type}
        value={value}
        name={name}
        id={id}
        required={!optional}
        pattern={pattern}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rest}
      />
    </label>
  );
};

export default LabelWithInput;

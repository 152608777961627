import React from 'react';
import classNames from 'html-classnames';
import { useHandleInputOnChange } from '../hooks';

function toPattern(values) {
  return values.join('|');
}

const LabelWithDatalistInput = ({
  text,
  name,
  id = name,
  value,
  values,
  onChange,
}) => (
  <label
    className={classNames('form__label', {
      '--with-input': true,
      [`--${name}`]: true,
      '--datalist': true,
    })}
    style={{
      gridArea: name,
    }}
    htmlFor={id}
  >
    <span>{text}</span>
    <input type="text" list={`datalist-${id}`} name={name} id={id} value={value} onChange={useHandleInputOnChange(onChange)} pattern={toPattern(values)} />
    <datalist id={`datalist-${id}`}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      {values.map((v) => (<option key={v} value={v} />))}
    </datalist>
  </label>
);

export default LabelWithDatalistInput;

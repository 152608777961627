import { useCallback } from 'react';

export const defaultSanitizer = (value) => value;
export const numberSanitizer = (value) => parseInt(value, 10);

export const useHandleInputOnChange = (onChange, sanitizer = defaultSanitizer) => useCallback(
  (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    onChange([event.target.name, sanitizer(value)]);
  },
  [onChange, sanitizer],
);
